// src/store/modules/OperationModule.ts
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import OperationService from '@/Services/OperationService';
import { Operation } from '@/models/Operation';

@Module({ namespaced: true })
export default class OperationModule extends VuexModule {
    operations: Operation[] = [];
    operation: Operation | null = null;
    error: string | null = null;
    totalRows:number | null = 0;

    @Mutation
    setOperations(operations: Operation[]) {
        this.operations = operations;
    }

    @Mutation
    setOperation(operation: Operation) {
        this.operation = operation;
    }

    @Mutation
    setError(error: string | null) {
        this.error = error;
    }

    @Mutation
    setTotalRows(totalRows: number) {
        this.totalRows = totalRows;
    }

    @Action
    async fetchOperation({id, page, pageSize, sortBy, sortOrder, search }: { id:number,page: number; pageSize: number; sortBy: string; sortOrder: string; search: string }) {
        try {
            const { data, totalRows } = await OperationService.getAll({id,page, pageSize, sortBy, sortOrder, search});
            this.context.commit('setOperations', data);
            this.context.commit('setTotalRows', totalRows);
            return { data, totalRows };
        } catch (error: any) {
            this.context.commit('setError', error.message || 'An error occurred while fetching operations.');
            throw error;
        }
    }

    get getAll() {
        return this.operations;
    }

    get getOperation() {
        return this.operation;
    }

    get getTotalRows() {
        return this.totalRows;
    }

    get getError() {
        return this.error;
    }
}
