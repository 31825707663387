// store/authModule.ts
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { saveTokens, clearTokens, getTokens, updateAccessToken } from '@/utils/cookieUtils';
import authService from '@/Services/authService';
import router from '@/router';

@Module({ namespaced: true })
export default class AuthModule extends VuexModule {
    accessToken: string | null = null;
    refreshToken: string | null = null;
    isAuthenticated = false;
    permissions: string[] = [];  

    @Mutation
    setTokens(tokens: Tokens) {
        this.accessToken = tokens.accessToken;
        this.refreshToken = tokens.refreshToken;
        this.isAuthenticated = true;
    }

    @Mutation
    setAccessToken(tokens: Tokens){
        this.accessToken = tokens.accessToken;
        this.refreshToken = tokens.refreshToken;
        this.isAuthenticated = true;
    }

    @Mutation
    setPermissions(permissions: string[]) {
        this.permissions = permissions;
    }

    @Mutation
    clearTokens() {
        this.accessToken = null;
        this.refreshToken = null;
        this.isAuthenticated = false;
        this.permissions = [];
    }

    @Action
    async login({ email, password }:{ email: string; password: string }) {
        try {
            const tokens = await authService.login({ email, password });   
            (tokens)         
            saveTokens(tokens);
            this.context.commit('setTokens', tokens);
            return tokens;
        } catch (error) {
            return error;
        }
    }

    @Action
    async logout() {
        try {
            const refreshToken = getTokens()?.refresh;
            if (refreshToken) {
                const response = await authService.logout(refreshToken);
                if (response==200) {
                    clearTokens();
                    router.push('/login')
                }
                return response;
            }
        } catch (error) {
            return error;
        }
    }

    @Action
    async verifyAccessToken() {
        try {
            const tokens = getTokens();
            if (!tokens || !tokens.access) throw new Error('Access token not found');
            const isValid = await authService.verifyToken(tokens.access);
            if (!isValid) {
                throw new Error('Invalid access token');
            }
            return isValid
        } catch (error) {
            clearTokens();
            return error;
        }
    }

    @Action
    async refreshAccessToken() {
        try {
            const tokens = getTokens();            
            if (!tokens || !tokens.refresh) throw new Error('Refresh token not found');
            const response = await authService.refresh(tokens.refresh);
            
            if (response.access) {
                const accessToken = response.access;
                updateAccessToken(accessToken); 
            } else if(response.status ==401){
                console.log(response);
                clearTokens(); 
                router.push('/login')
            }
        } catch (error) {
            clearTokens();
            router.push('/login')
            return error;
        }
    }

    @Action
    async getPermissionsUser() {
        try {
            const tokens = getTokens();
            if (!tokens || !tokens.access) throw new Error('Access token not found');
            const permissions = await authService.getUserPermissions(tokens.access);
            if (permissions) {
                this.context.commit('setPermissions', permissions);
            } else {
                clearTokens();
                router.push('/login');
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des permissions:', error);
            clearTokens();
            router.push('/login');
            return error;
        }
    }
}

interface Tokens {
    accessToken: string;
    refreshToken: string;
}
