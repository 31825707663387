import axiosInstance from '@/utils/axiosConfig';
import axios from 'axios';

const DashboardService = {
    async getCountItemsOfCategory(): Promise<any | { status: number; message: string }> {
        try {
            const response = await axiosInstance.get(`/categories/items-count/`);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {   
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while fetching data.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
    async getCounttagsOfType(): Promise<any | { status: number; message: string }> {
        try {
            const response = await axiosInstance.get(`/type_tags/tags-count/`);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while fetching data.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
    async getCountItems(): Promise<any | { status: number; message: string }> {
        try {
            const response = await axiosInstance.get(`/items/count/`);
            return response.data.count;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while fetching data.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
    async getCountArchiveItem(): Promise<any | { status: number; message: string }> {
        try {
            const response = await axiosInstance.get(`/items/archive/count/`);
            return response.data.count;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while fetching data.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
    async getCountTags(): Promise<any | { status: number; message: string }> {
        try {
            const response = await axiosInstance.get(`/tags/count/`);
            return response.data.count;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while fetching data.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
    async getCountArticlereceptionner(): Promise<any | { status: number; message: string }> {
        try {
            const response = await axiosInstance.get(`/article/count/`);
            return response.data.count;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while fetching data.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
    async getCountItemsAmortizition(year:number): Promise<any | { status: number; message: string }> {
        try {
            const response = await axiosInstance.get(`/item/amortization-count/${year}/`);
            
            
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while fetching data.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
    async getValueAmortizition(): Promise<any | { status: number; message: string }> {
        try {
            const response = await axiosInstance.get(`/residual-value/global/current-year/`);
            
            
            return response.data.total_residual_value;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while fetching data.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
    async getValuePerDepartement(year:number): Promise<any | { status: number; message: string }> {
        try {
            const response = await axiosInstance.get(`/financial-value-by-department/${year}/`);
            
            
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while fetching data.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
    async getCountEmplacementPerLocation(): Promise<any | { status: number; message: string }> {
        try {
            const response = await axiosInstance.get(`/locations-count/`);
            
            
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while fetching data.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
    async getCountItemPerDepartemnt(): Promise<any | { status: number; message: string }> {
        try {
            const response = await axiosInstance.get(`/departements-item-count/`);
            
            
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while fetching data.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
    async getCountItemwithValuePerPersonne(): Promise<any | { status: number; message: string }> {
        try {
            const response = await axiosInstance.get(`/items/personnes/summaries/`);
            
            
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while fetching data.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
};

export default DashboardService;
