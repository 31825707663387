// store/modules/ArticleVuex.ts
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import TagHistoryService from '@/Services/TagHistoryService';
import {TagHistory} from '@/models/TagHistory';
import TagService from '@/Services/TagService';
@Module({ namespaced: true })
export default class TagModule extends VuexModule {
    tags: TagHistory[] = [];
    error: string | null = null;
    tag: TagHistory | null = null;

    @Mutation
    setTags(Tags: TagHistory[]) {
        this.tags = Tags;
    }
    @Mutation
    setTag(Tag: TagHistory) {
        this.tag =Tag;
    }

    @Mutation
    setError(error: string | null) {
        this.error = error;
    }

    @Action
    async fetchTags({id, page, pageSize, sortBy, sortOrder, search }: { id:number,page: number; pageSize: number; sortBy: string; sortOrder: string; search: string }) {
        try {
            const { data, totalRows } = await TagHistoryService.getAll({id,page, pageSize, sortBy, sortOrder, search});
            return { data, totalRows };
        } catch (error:any) {
            this.context.commit('setError', error.message || 'An error occurred while fetching tags.');
            throw error;
        }
    }

    get getAll() {
        return this.tags;
    }
    get getTag() {
        return this.tag;
    }

}
