import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import FournisseurService from '@/Services/FournisseurService';
import { Fournisseur } from '@/models/Fournisseurs';
@Module({ namespaced: true })
export default class FournisseurModule extends VuexModule {
    fournisseurs: Fournisseur[] = [];
    error: string | null = null;
    @Mutation
    setFournisseurs(fournisseurs: Fournisseur[]) {
        this.fournisseurs = fournisseurs;
    }
    @Mutation
    setError(error: string | null) {
        this.error = error;
    }

    @Action
    async fetchFournisseurs() {
        try {
            const response = await FournisseurService.getAllFournisseurs();  
            console.log(response);
            
            return response
        } catch (error: any) {
            return error;
        }
    }
    get getAll() {
        return this.fournisseurs;
    }

}
