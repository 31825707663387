import axiosInstance from "@/utils/axiosConfig";
import Article from "@/models/Article";
import axios from 'axios';

const API_URL = '/article'; // Base URL n'est plus nécessaire ici

interface ErrorResponse {
    status: number;
    message: string;
}

const ArticleService = {
    // Create a new Article with file import
    async createwithImport(file: File[]): Promise<number | ErrorResponse> {
        try {
            const formData = new FormData();
            formData.append('file', file[0]);

            const response = await axiosInstance.post(`${API_URL}/import/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            return response.status;
        } catch (error) {
            if (axios.isAxiosError(error)) {


                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data ?? 'An error occurred'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred'
            };
        }
    },

    async create(article: Article): Promise<number | ErrorResponse> {
        try {
            const formData = new FormData();
            formData.append("date_achat", article.date_achat?.toString() ? article.date_achat?.toString() : '')
            formData.append('code_article', article.code_article);
            formData.append('N_facture', article.N_facture);
            formData.append('prix_achat', article.prix_achat?.toString() || ''); // Convert number to string, handle null
            formData.append('qte_recue', article.qte_recue?.toString() || '');   // Convert number to string, handle null
            formData.append('designation', article.designation);
            formData.append('numero_serie', article.numero_serie);
            formData.append('numero_comptable', article.numero_comptable ?? ''); 
            formData.append('couleur', article.couleur ?? '');                   // Handle null
            formData.append('poids', article.poids?.toString() || '');            // Convert number to string, handle null
            formData.append('volume', article.volume?.toString() || '');          // Convert number to string, handle null
            formData.append('langueur', article.langueur?.toString() || '');      // Convert number to string, handle null
            formData.append('hauteur', article.hauteur?.toString() || '');        // Convert number to string, handle null
            formData.append('largeur', article.largeur?.toString() || '');        // Convert number to string, handle null
            formData.append('produit', article.produit?.toString() || '');        // Convert number to string, handle null

            // Handle image if it's present
            if (article.image && article.image.length > 0) {
                formData.append('image', article.image[0]);
            }

            // Ajouter d'autres fichiers si nécessaires
            if (article.attachement1 instanceof File) {
                formData.append('attachement1', article.attachement1);
            }
            if (article.attachement2 instanceof File) {
                formData.append('attachement2', article.attachement2);
            }
            if (article.attachement3 instanceof File) {
                formData.append('attachement3', article.attachement3);
            }

            const response = await axiosInstance.post(`${API_URL}/create/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            return response.status;
        } catch (error) {
            console.log(error);

            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred'
            };
        }
    },



    async getAll(page: number, pageSize: number, sortBy: string, sortOrder: string, search: string) {
        try {
            const params = new URLSearchParams({
                page: page.toString(),
                pageSize: pageSize.toString(),
                ordering: `${sortOrder}${sortBy}`,
                search: search,
            });
            const response = await axiosInstance.get(`${API_URL}/all_articles/?${params.toString()}`);
            return { data: response.data.results, totalRows: response.data.count };
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred'
            };
        }
    },


    async getAllConsomme(page: number, pageSize: number, sortBy: string, sortOrder: string, search: string) {
        try {
            const params = new URLSearchParams({
                page: page.toString(),
                pageSize: pageSize.toString(),
                ordering: `${sortOrder}${sortBy}`,
                search: search,
            });
            const response = await axiosInstance.get(`${API_URL}/all_articles_Consommes/?${params.toString()}`);
            return { data: response.data.results, totalRows: response.data.count };
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred'
            };
        }
    },

    async getById(id: number): Promise<Article | ErrorResponse> {
        try {
            const response = await axiosInstance.get(`${API_URL}/get_article/${id}/`);
            return response.data as Article;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred'
            };
        }
    },

    async supprimer(id: number) {
        try {
            const response = await axiosInstance.delete(`${API_URL}/supprimer/${id}/`);
            return response.status;
        } catch (error) {
            if (axios.isAxiosError(error)) {


                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data ?? 'An error occurred'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred'
            };
        }
    },

    async update({ id, article }: { id: number, article: Article }): Promise<number | ErrorResponse> {
        try {
            const formData = new FormData();
            
            formData.append('code_article', article.code_article);
            formData.append('N_facture', article.N_facture);
            formData.append('designation', article.designation);
            formData.append("date_achat", article.date_achat?.toString() ? article.date_achat?.toString() : '')
            formData.append('prix_achat', article.prix_achat?article.prix_achat.toString() : '');
            formData.append('numero_serie', article.numero_serie);
            formData.append('numero_comptable', article.numero_comptable ?? '');
            formData.append('couleur', article.couleur ?? '');
            formData.append('poids', article.poids ? article.poids.toString() : '');
            formData.append('volume', article.volume ? article.volume.toString() : '');
            formData.append('langueur', article.langueur ? article.langueur.toString() : '');
            formData.append('hauteur', article.hauteur ? article.hauteur.toString() : '');
            formData.append('largeur', article.largeur ? article.largeur.toString() : '');
            formData.append('produit', article.produit ? article.produit.toString() : '');
            formData.append('nature', article.nature ? article.nature.toString() : '');
            formData.append('marque', article.marque ? article.marque.toString() : '');
            formData.append('fournisseur', article.fournisseur ? article.fournisseur.toString() : '');
            if (article.image && article.image.length > 0 && article.image[0] instanceof File) {
                formData.append('image', article.image[0]);
            }
            // Ajouter d'autres fichiers si nécessaires
            if (article.attachement1 instanceof File) {
                formData.append('attachement1', article.attachement1);
            }
            if (article.attachement2 instanceof File) {
                formData.append('attachement2', article.attachement2);
            }
            if (article.attachement3 instanceof File) {
                formData.append('attachement3', article.attachement3);
            }

            const response = await axiosInstance.put(`${API_URL}/update_article/${id}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            return response.status;
        } catch (error) {

            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred'
            };
        }
    },
    async extractArticles({ id, page, pageSize, sortBy, sortOrder, search }: { id: number, page: number; pageSize: number; sortBy: string; sortOrder: string; search: string }): Promise<Blob | ErrorResponse> {
        try {
            const params = new URLSearchParams({
                page: page.toString(),
                pageSize: pageSize.toString(),
                ordering: `${sortOrder}${sortBy}`,
                search: search,
                export: 'excel'
            });
            const response = await axiosInstance.get(`${API_URL}/export/${params.toString()}`, {
                responseType: 'blob' // Indique que nous attendons un fichier en réponse
            }); 
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred'
            };
        }
    }
};

export default ArticleService;
