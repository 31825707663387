import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import NatureService from '@/Services/NatureService';
import { Nature } from '@/models/Nature';
@Module({ namespaced: true })
export default class NatureModule extends VuexModule {
    Natures: Nature[] = [];
    error: string | null = null;
    @Mutation
    setNatures(Natures: Nature[]) {
        this.Natures = Natures;
    }
    @Mutation
    setError(error: string | null) {
        this.error = error;
    }

    @Action
    async fetchNatures() {
        try {
            const response = await NatureService.getAllNatures();
            return response
        } catch (error: any) {
            return error;
        }
    }
    get getAll() {
        return this.Natures;
    }

}
