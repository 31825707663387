import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import LocationService from '@/Services/LocationService';
import { Location } from '@/models/Location';
@Module({ namespaced: true })
export default class LocationModule extends VuexModule {
    Locations: Location[] = [];
    error: string | null = null;
    @Mutation
    setLocations(Locations: Location[]) {
        this.Locations = Locations;
    }
    @Mutation
    setError(error: string | null) {
        this.error = error;
    }

    @Action
    async fetchLocations() {
        try {
            const response = await LocationService.getAllLocations();
            return response
        } catch (error: any) {
            return error;
        }
    }
    get getAll() {
        return this.Locations;
    }

}
