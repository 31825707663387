// src/store/modules/TransfertModule.ts
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import TransfertService from '@/Services/TransfertService';

@Module({ namespaced: true })
export default class TransfertModule extends VuexModule {

    error: string | null = null;
    totalRows:number | null = 0;



    @Mutation
    setError(error: string | null) {
        this.error = error;
    }

    @Mutation
    setTotalRows(totalRows: number) {
        this.totalRows = totalRows;
    }

    @Action
    async fetchTransfert({id, page, pageSize, sortBy, sortOrder, search }: { id:number,page: number; pageSize: number; sortBy: string; sortOrder: string; search: string }) {
        try {
            const { data, totalRows } = await TransfertService.getAll({id,page, pageSize, sortBy, sortOrder, search});
            return { data, totalRows };
        } catch (error: any) {
            this.context.commit('setError', error.message || 'An error occurred while fetching Transferts.');
            throw error;
        }
    }



    get getTotalRows() {
        return this.totalRows;
    }

    get getError() {
        return this.error;
    }
}
