import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import ZoneService from '@/Services/ZoneService';
import { Zone } from '@/models/Zone';
@Module({ namespaced: true })
export default class ZoneModule extends VuexModule {
    Zones: Zone[] = [];
    error: string | null = null;
    @Mutation
    setZones(Zones: Zone[]) {
        this.Zones = Zones;
    }
    @Mutation
    setError(error: string | null) {
        this.error = error;
    }

    @Action
    async fetchZones() {
        try {
            const response = await ZoneService.getAllZones();
            return response
        } catch (error: any) {
            return error;
        }
    }
    async fetchZonesByLocation(id:number) {
        try {
            const response = await ZoneService.getZonesByLocation(id);
            return response
        } catch (error: any) {
            return error;
        }
    }
    get getAll() {
        return this.Zones;
    }

}
