import axios from 'axios';
import Item from "@/models/Items"
import baseURL  from '@/API';
import axiosInstance from "@/utils/axiosConfig";


const API_URL = baseURL + '/items';

interface ErrorResponse {
    status: number;
    message: string;
}



const ItemService = {
   
    async getAll(page: number, pageSize: number, sortBy: string, sortOrder: string, search: string){
        try {
            const params = new URLSearchParams({
                page: page.toString(),
                pageSize: pageSize.toString(),
                ordering: `${sortOrder}${sortBy}`, 
                search: search,
            });
            const response = await axiosInstance.get(`${API_URL}/all_item/?${params.toString()}`);
            return { data: response.data.results, totalRows: response.data.count };
        } catch (error: any) {
            return error;
        }
    },
    async extractAll(page: number, pageSize: number, sortBy: string, sortOrder: string, search: string){
        try {
            const params = new URLSearchParams({
                page: page.toString(),
                pageSize: pageSize.toString(),
                ordering: `${sortOrder}${sortBy}`, 
                search: search,
                export:'excel'
            });
            const response = await axiosInstance.get(`${API_URL}/all_item/?${params.toString()}`, {
                responseType: 'blob' // Indique qu'on attend un fichier binaire
            });
            return response;
        } catch (error: any) {
            return error;
        }
    },

   
};

export default ItemService;
