import axios from 'axios';
import  API  from '@/API';
const API_URL = `${API}/api`; // Assurez-vous que API est bien configuré
interface ErrorResponse {
    status: number;
    message: string;
}

interface Tokens {
    access: string;
    refresh: string;
}

const authService = {
    async login({ email, password }: { email: string; password: string }): Promise<Tokens> {
        try {
            const response = await axios.post(`${API_URL}/token/`, { email, password });
            return response.data as Tokens;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return Promise.reject({
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred during login.'
                });
            }
            return Promise.reject({
                status: 500,
                message: 'An unexpected error occurred.'
            });
        }
    },

    async logout(refreshToken: string) {
        try {
            const response = await axios.post(`${API_URL}/token/blacklist/`, { refresh: refreshToken });
            return response.status
        } catch (error) {
            // Gérez les erreurs en propagent l'erreur
            if (axios.isAxiosError(error)) {
                return Promise.reject({
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred during logout.'
                });
            }
            return Promise.reject({
                status: 500,
                message: 'An unexpected error occurred.'
            });
        }
    },

    async refresh(refreshToken: string) {
        try {
            const response = await axios.post(`${API_URL}/token/refresh/`, { refresh: refreshToken });
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return Promise.reject({
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred during token refresh.'
                });
            }
            return Promise.reject({
                status: 500,
                message: 'An unexpected error occurred.'
            });
        }
    },

    async verifyToken(accessToken: string): Promise<boolean> {
        try {
            const response = await axios.post(`${API_URL}/token/verify/`, { token: accessToken });
            return response.status === 200;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return Promise.reject({
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while verifying the token.'
                });
            }
            return Promise.reject({
                status: 500,
                message: 'An unexpected error occurred.'
            });
        }
    },

    async getUserPermissions(accessToken: string): Promise<string[]> {
        try {
            const response = await axios.get(`${API_URL}/user/permissions/`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            return response.data.permissions;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return Promise.reject({
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while fetching permissions.'
                });
            }
            return Promise.reject({
                status: 500,
                message: 'An unexpected error occurred.'
            });
        }
    }
};

export default authService;
