// store/modules/ArticleVuex.ts
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import ItemsService from '@/Services/ItemsService';
import Item from '@/models/Items';
@Module({ namespaced: true })
export default class ItemModule extends VuexModule {
    Items: Item[] = [];
    error: string | null = null;
    Item: Item | null = null;

    @Mutation
    setArticles(Items: Item[]) {
        this.Items = Items;
    }
    @Mutation
    setArticle(Item: Item) {
        this.Item = Item;
    }

    @Mutation
    setError(error: string | null) {
        this.error = error;
    }

    @Action
    async fetchItems({ page, pageSize, sortBy, sortOrder, search }: { page: number; pageSize: number; sortBy: string; sortOrder: string; search: string }) {
        try {
            const { data, totalRows } = await ItemsService.getAll(page, pageSize, sortBy, sortOrder, search);
            return { data, totalRows };
        } catch (error:any) {
            this.context.commit('setError', error.message || 'An error occurred while fetching items.');
            throw error;
        }
    }

    @Action
    async extractItems({ page, pageSize, sortBy, sortOrder, search }: { page: number; pageSize: number; sortBy: string; sortOrder: string; search: string }) {
        try {
            const { data, totalRows } = await ItemsService.extractAll(page, pageSize, sortBy, sortOrder, search);
            return { data, totalRows };
        } catch (error:any) {
            this.context.commit('setError', error.message || 'An error occurred while fetching items.');
            throw error;
        }
    }

    
    get getAll() {
        return this.Items;
    }
    get getItem() {
        return this.Item;
    }

}
