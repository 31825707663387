import axiosInstance from '@/utils/axiosConfig'; // Importer l'instance Axios configurée
import axios from 'axios';

const FournisseurService = {
    // Retrieve all Fournisseurs
    async getAllFournisseurs(): Promise<any | { status: number; message: string }> {
        try {
            const response = await axiosInstance.get('/fournisseurs/all_fournisseur/');
            console.log(response.status); // Pour débogage, vous pouvez enlever cette ligne en production
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                // Retourner un objet avec le statut et le message d'erreur
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while fetching fournisseurs.'
                };
            }
            // Retourner un objet avec le statut 500 pour les erreurs inattendues
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
};

export default FournisseurService;
