import axiosInstance from '@/utils/axiosConfig'; // Importez l'instance Axios configurée
import axios from 'axios';
const API_URL = '/locations';

const LocationService = {
    async getAllLocations() {
        try {
            const response = await axiosInstance.get(`${API_URL}/all_location/`);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                // Renvoyer un message d'erreur plus détaillé
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while fetching locations.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
};

export default LocationService;
