// src/services/OperationService.ts
import axios from 'axios';
import baseURL from '@/API';
import axiosInstance from "@/utils/axiosConfig";

const API_URL = `${baseURL}/items`;

interface ErrorResponse {
    status: number;
    message: string;
}

const OperationService = {
    async getAll({id, page, pageSize, sortBy, sortOrder, search }: { id:number,page: number; pageSize: number; sortBy: string; sortOrder: string; search: string }) {
        try {
            const params = new URLSearchParams({
                page: page.toString(),
                pageSize: pageSize.toString(),
                ordering: `${sortOrder === 'desc' ? '-' : ''}${sortBy}`,
                search: search,
            });

            const response = await axiosInstance.get(`${API_URL}/all_operation/${id}/?${params.toString()}`);

            if (response.data) {
                return { data: response.data.results, totalRows: response.data.count };
            } else {
                throw new Error('No data found');
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'An unknown error occurred';
            console.error('Error fetching operations:', errorMessage);
            throw new Error(errorMessage);
        }
    },
};

export default OperationService;
