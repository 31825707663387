// store/modules/ArticleVuex.ts
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import DetailService from '@/Services/DetailInventaireService';
import {DetailInventaire} from '@/models/DetailInventaire';
import { isErrorResponse } from '@/utils/typeGuards';
@Module({ namespaced: true })
export default class DetailInventaireModule extends VuexModule {
    Items: DetailInventaire[] = [];
    error: string | null = null;
    Item: DetailInventaire | null = null;

    @Mutation
    setArticles(Items: DetailInventaire[]) {
        this.Items = Items;
    }
    @Mutation
    setArticle(Item: DetailInventaire) {
        this.Item = Item;
    }

    @Mutation
    setError(error: string | null) {
        this.error = error;
    }

    @Action
    async fetchDetail({id, page, pageSize, sortBy, sortOrder, search }: { id:number,page: number; pageSize: number; sortBy: string; sortOrder: string; search: string }) {
        try {
            const { data, totalRows } = await DetailService.getAll({id, page, pageSize, sortBy, sortOrder, search });
            return { data, totalRows };
        } catch (error:any) {
            this.context.commit('setError', error.message || 'An error occurred while fetching items.');
            throw error;
        }
    }
    @Action
    async fetchDetailInfo(id:number) {
        try {
            const response = await DetailService.getInfo(id);
            return response;
        } catch (error:any) {
            this.context.commit('setError', error.message || 'An error occurred while fetching items.');
            throw error;
        }
    }
    @Action
    async extractAllDetail({id, page, pageSize, sortBy, sortOrder, search }: { id:number,page: number; pageSize: number; sortBy: string; sortOrder: string; search: string }) {
        try {
            const response = await DetailService.extractAll({id, page, pageSize, sortBy, sortOrder, search });
            if (isErrorResponse(response)) {
                this.context.commit('setError', response.message || 'An error occurred while extracting articles.');
                return null; // Return null on error
            }
            return response;
        } catch (error:any) {
            return error;
        }
    }


    get getAll() {
        return this.Items;
    }
    get getItem() {
        return this.Item;
    }

}