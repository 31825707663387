// store/modules/InventaireVuex.ts
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import InventaireService from '@/Services/InventorySiteService';
import { Inventaire, InventaireWithDepartement, InventaireWithLocation, InventaireWithZone } from '@/models/Inventaire';
import { isErrorResponse } from '@/utils/typeGuards';
import { ErrorResponse } from '@/types/ErrorResponse';
@Module({ namespaced: true })
export default class InventaireModule extends VuexModule {
    Inventaires: Inventaire[] = [];
    error: string | null = null;
    Inventaire: Inventaire | null = null;

    @Mutation
    setInventaires(Inventaires: Inventaire[]) {
        this.Inventaires = Inventaires;
    }
    @Mutation
    setInventaire(Inventaire: Inventaire) {
        this.Inventaire = Inventaire;
    }

    @Mutation
    setError(error: string | null) {
        this.error = error;
    }

    @Action
    async fetchInventaires({ page, pageSize, sortBy, sortOrder, search }: { page: number; pageSize: number; sortBy: string; sortOrder: string; search: string }) {
        try {
            const response = await InventaireService.getAll(page, pageSize, sortBy, sortOrder, search);
            if (isErrorResponse(response)) {
                this.context.commit('setError', response.message || 'An error occurred while fetching Inventaires.');
                throw new Error(response.message);
            } else {
                const { data, totalRows } = response;
                this.context.commit('setInventaires', data);
                return { data, totalRows };
            }
        } catch (error: any) {
            this.context.commit('setError', error.message || 'An error occurred while fetching Inventaires.');
            return error;
        }
    }
    @Action
    async fetchInventairesZone({ page, pageSize, sortBy, sortOrder, search }: { page: number; pageSize: number; sortBy: string; sortOrder: string; search: string }) {
        try {
            const { data, totalRows } = await InventaireService.getAllInventaireZone(page, pageSize, sortBy, sortOrder, search);
            return { data, totalRows };
        } catch (error: any) {
            // this.context.commit('setError', error.message || 'An error occurred while fetching Inventaires.');
            return error;
        }
    }
    @Action
    async fetchInventairesLocation({ page, pageSize, sortBy, sortOrder, search }: { page: number; pageSize: number; sortBy: string; sortOrder: string; search: string }) {
        try {
            const { data, totalRows } = await InventaireService.getAllInventaireLocation(page, pageSize, sortBy, sortOrder, search);
            return { data, totalRows };
        } catch (error: any) {
            // this.context.commit('setError', error.message || 'An error occurred while fetching Inventaires.');
            return error;
        }
    }
    @Action
    async fetchInventairesDepartement({ page, pageSize, sortBy, sortOrder, search }: { page: number; pageSize: number; sortBy: string; sortOrder: string; search: string }) {
        try {
            const { data, totalRows } = await InventaireService.getAllInventaireDepartements(page, pageSize, sortBy, sortOrder, search);
            return { data, totalRows };
        } catch (error: any) {
            // this.context.commit('setError', error.message || 'An error occurred while fetching Inventaires.');
            return error;
        }
    }

    @Action
    async getById(id: number) {
        try {
            const Inventaire = await InventaireService.getById(id);

            if (Inventaire) {
                this.context.commit('setInventaire', Inventaire); // Correction ici
            }
            return Inventaire

        } catch (error: any) {
            return error
        }
    }


    @Action
    async addInventaire(Inventaire: Inventaire) { //
        try {
            const response = await InventaireService.create(Inventaire);
            return response
        } catch (error: any) {
            return error
        }
    }
    @Action
    async addInventaireDepartement(inventaire: InventaireWithDepartement) {
        try {
            const response = await InventaireService.addInventoryDepartement(inventaire);
            return response;
        } catch (error: any) {
            return error;
        }
    }

    @Action
    async addInventaireZone(inventaire: InventaireWithZone) {
        try {
            const response = await InventaireService.addInventoryZone(inventaire);
            return response;
        } catch (error: any) {
            return error;
        }
    }

    @Action
    async addInventaireLocation(inventaire: InventaireWithLocation) {
        try {
            const response = await InventaireService.addInventoryLocation(inventaire);
            return response;
        } catch (error: any) {
            console.log(error);

            return error;
        }
    }


    @Action
    async updateInventaire({ id, inventaire }: { id: number, inventaire: Inventaire }) {
        try {
            const response = await InventaireService.update(id, inventaire);
            return response
        } catch (error: any) {
            this.context.commit('setError', error.message || 'Une erreur s\'est produite');
            return error
        }
    }
    @Action
    async getInventaireDepartementById(id: number) {
        try {
            const response = await InventaireService.getByIdInventoryDepartement(id);
            return response;
        } catch (error: any) {
            return error;
        }
    }

    @Action
    async updateInventaireDepartement({ id, inventaire }: { id: number, inventaire: InventaireWithDepartement }) {
        try {
            const response = await InventaireService.updateInventoryDepartement({ id, inventaire });
            return response;
        } catch (error: any) {
            return error;
        }
    }

    @Action
    async getInventaireZoneById(id: number) {
        try {
            const response = await InventaireService.getByIdInventoryZone(id);
            return response;
        } catch (error: any) {
            return error;
        }
    }

    @Action
    async updateInventaireZone({ id, inventaire }: { id: number, inventaire: InventaireWithZone }) {
        try {
            const response = await InventaireService.updateInventoryZone({ id, inventaire });
            return response;
        } catch (error: any) {
            return error;
        }
    }

    @Action
    async getInventaireLocationById(id: number) {
        try {
            const response = await InventaireService.getByIdInventoryLocation(id);
            return response;
        } catch (error: any) {
            return error;
        }
    }

    @Action
    async updateInventaireLocation({ id, inventaire }: { id: number, inventaire: InventaireWithLocation }) {
        try {
            const response = await InventaireService.updateInventoryLocation({ id, inventaire });
            return response;
        } catch (error: any) {
            return error;
        }
    }
    @Action
    async getDetailInventaireParemplacement({ id, page, pageSize, sortBy, sortOrder, search }: { id: number, page: number, pageSize: number, sortBy: string, sortOrder: string, search: string }) {
        try {
            const response = await InventaireService.getDetailInventaireParemplacements({ id, page, pageSize, sortBy, sortOrder, search });
            return response;
        } catch (error: any) {
            return error;
        }
    }
    @Action
    async updateStartAt(ids: number[]): Promise<number | ErrorResponse> {
        try {
            const result = await InventaireService.lancerEmplacement(ids);
            return result;
        } catch (error: any) {
            return { status: 500, message: 'An unexpected error occurred.' };
        }
    }
    @Action
    async fetchEmplacementCount(inventaireId: number) {
        try {
            const counts = await InventaireService.getEmplacementCounts(inventaireId);
                return counts
        } catch (error: any) {
            return { status: 500, message: 'An unexpected error occurred.' };
        }
    }

    get getAll() {
        return this.Inventaires;
    }
    get getInventaire() {
        return this.Inventaire;
    }

}
