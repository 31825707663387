import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import DepartementService from '@/Services/DepartementService';
import { Departement } from '@/models/Departement';
@Module({ namespaced: true })
export default class DepartementModule extends VuexModule {
    Departements: Departement[] = [];
    error: string | null = null;
    @Mutation
    setDepartements(Departements: Departement[]) {
        this.Departements = Departements;
    }
    @Mutation
    setError(error: string | null) {
        this.error = error;
    }

    @Action
    async fetchDepartements() {
        try {
            const response = await DepartementService.getAllDepartements();
            return response
        } catch (error: any) {
            return error;
        }
    }
    get getAll() {
        return this.Departements;
    }

}
