import axios from 'axios';
import baseURL  from '@/API';
import axiosInstance from "@/utils/axiosConfig";


// const API_URL = baseURL + '/tag';

interface ErrorResponse {
    status: number;
    message: string;
}



const TagService = {
    
    async getAll({id, page, pageSize, sortBy, sortOrder, search }:{id:number,page: number, pageSize: number, sortBy: string, sortOrder: string, search: string}){
        try {
            const params = new URLSearchParams({
                page: page.toString(),
                pageSize: pageSize.toString(),
                ordering: `${sortOrder === 'desc' ? '-' : ''}${sortBy}`, 
                search: search,
            });
            const response = await axiosInstance.get(`/tag/all_Tag_History/${id}/?${params.toString()}`);
            return { data: response.data.results, totalRows: response.data.count };
        } catch (error: any) {
            return error;
        }
    },

    
};

export default TagService;
