import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import PermissionService from '@/Services/PermissionsService';

@Module({ namespaced: true })
export default class PermissionModule extends VuexModule {
    Permissions: string[] = [];
    error: string | null = null;

    @Mutation
    setPermissions(Permissions: string[]) {
        this.Permissions = Permissions;
    }

    @Mutation
    setError(error: string | null) {
        this.error = error;
    }

    @Action({ commit: 'setPermissions', rawError: true })
    async fetchPermissions() {
        try {
            const response = await PermissionService.getPermissionsByUser();
            if (response.status && response.status !== 200) {
                this.context.commit('setError', response.message);
                return [];
            }
            return response.permissions; // Applique la mutation `setPermissions`
        } catch (error: any) {
            this.context.commit('setError', 'Failed to fetch permissions');
            return [];
        }
    }

    get getAll() {
        return this.Permissions;
    }
}
