// util/cookieUtils.ts
import Cookies from 'js-cookie';

interface Tokens {
    access: string | null;
    refresh: string | null;
}

const TOKENS_COOKIE_NAME = 'my_app_tokens';
export function saveTokens(tokens: Tokens): void {
    const tokensString = JSON.stringify(tokens);
    console.log("NODE_ENV:", process.env.NODE_ENV);
    // Cookies.set(TOKENS_COOKIE_NAME, tokensString, { secure: process.env.NODE_ENV === 'production', sameSite: 'strict'});
    Cookies.set(TOKENS_COOKIE_NAME, tokensString, { secure: false, sameSite: 'strict'});

}

export function getTokens(): Tokens | null {
    const tokensString = Cookies.get(TOKENS_COOKIE_NAME);
    // console.log(tokensString);
    
    if (tokensString) {
        return JSON.parse(tokensString) as Tokens;
    }
    return null;
}


export function updateAccessToken(newAccessToken: string): void {
    const tokens = getTokens();
    // clearTokens()
    if (tokens) {
        tokens.access = newAccessToken;
        saveTokens(tokens);
    }
}


export function clearTokens(): void {
    Cookies.remove(TOKENS_COOKIE_NAME);
}
