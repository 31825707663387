import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import ProduitService from '@/Services/ProduitService';
import { Produit } from '@/models/Produit';
@Module({ namespaced: true })
export default class ProduitModule extends VuexModule {
    Produits: Produit[] = [];
    error: string | null = null;
    @Mutation
    setProduits(Produits: Produit[]) {
        this.Produits = Produits;
    }
    @Mutation
    setError(error: string | null) {
        this.error = error;
    }

    @Action
    async fetchProduits() {
        try {
            const response = await ProduitService.getAllProduits();
            return response
        } catch (error: any) {
            return error;
        }
    }
    get getAll() {
        return this.Produits;
    }

}
