import axiosInstance from '@/utils/axiosConfig'; // Importer l'instance Axios configurée
import axios from 'axios';

const DepartementService = {
    // Retrieve all Departements
    async getAllDepartements(): Promise<any | { status: number; message: string }> {
        try {
            const response = await axiosInstance.get(`/departements/all_departement/`);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while fetching departements.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
};

export default DepartementService;
