// axiosConfig.ts
import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import store from '@/store';
import { clearTokens, getTokens } from './cookieUtils';
import baseURL from '@/API';
import router from '@/router';
import Swal from 'sweetalert2';

interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
    _retry?: boolean;
}

// Créer une instance Axios avec la configuration de base
const axiosInstance = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Intercepteur de requêtes sortantes
axiosInstance.interceptors.request.use(
    async (config: CustomAxiosRequestConfig) => {
        const tokens = getTokens();

        if (tokens?.access) {
            try {
                // Vérifiez si le token d'accès est valide
                const isValid = await store.dispatch('AuthModule/verifyAccessToken', tokens.access);
                if (isValid) {
                    // Si valide, ajouter le token à l'en-tête Authorization
                    config.headers['Authorization'] = `Bearer ${tokens.access}`;
                } else {
                    // Sinon, on tente un rafraîchissement
                    config.headers['Authorization'] = '';
                }
            } catch (error) {
                config.headers['Authorization'] = ''; // Retirer le token si une erreur survient
            }
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Intercepteur de réponses pour gérer les erreurs 401 (Unauthorized)
axiosInstance.interceptors.response.use(
    (response) => response, // Passer la réponse si elle est valide
    async (error: AxiosError) => {
        const originalRequest = error.config as CustomAxiosRequestConfig;

        if (originalRequest && error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                // Rafraîchir le token d'accès
                await store.dispatch('AuthModule/refreshAccessToken');
                const tokens = getTokens();

                if (tokens && tokens.access) {
                    // Mise à jour de l'en-tête Authorization avec le nouveau token
                    originalRequest.headers['Authorization'] = `Bearer ${tokens.access}`;
                    // Refaire la requête originale avec le nouveau token
                    return axiosInstance(originalRequest);
                }
            } catch (refreshError) {
                clearTokens(); // Supprimer les tokens si le rafraîchissement échoue

                // Afficher l'alerte SweetAlert avec un bouton de redirection
                Swal.fire({
                    title: 'Session Expirée',
                    text: 'Votre session a expiré, veuillez vous reconnecter.',
                    icon: 'warning',
                    confirmButtonText: 'Se reconnecter',
                    allowOutsideClick: false, // Empêche de cliquer en dehors de l'alerte
                    allowEscapeKey: false,   // Empêche de fermer l'alerte avec "Échap"
                    allowEnterKey: false,    // Empêche de fermer l'alerte avec "Entrée"
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        // Redirection vers la page de login
                        router.push('/login');
                    }
                });
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
