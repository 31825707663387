import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import EmplacementService from '@/Services/EmplacementService';
import { Emplacement } from '@/models/Emplacement';
@Module({ namespaced: true })
export default class EmplacementModule extends VuexModule {
    Emplacements: Emplacement[] = [];
    error: string | null = null;
    @Mutation
    setEmplacements(Emplacements: Emplacement[]) {
        this.Emplacements = Emplacements;
    }
    @Mutation
    setError(error: string | null) {
        this.error = error;
    }

    @Action
    async fetchEmplacements() {
        try {
            const response = await EmplacementService.getAllEmplacements();
            return response
        } catch (error: any) {
            return error;
        }
    }
    get getAll() {
        return this.Emplacements;
    }

}
