import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.css';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Chart from 'chart.js/auto';
import 'vuetify/dist/vuetify.min.css';
import vuetify from '@/plugins/vuetify';
import Vue3Datatable from "@bhplugin/vue3-datatable";
import "@bhplugin/vue3-datatable/dist/style.css";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import 'bootstrap'; // Import Bootstrap JS (optionnel, si vous avez besoin de composants JavaScript)


const app = createApp(App);

app.use(store);
app.use(router);
app.use(Vue3Datatable);
app.config.globalProperties.$Chart = Chart;
app.use(vuetify);
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');
