import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import ArticleService from '@/Services/ArticlesService';
import Article from '@/models/Article';
import { isErrorResponse } from '@/utils/typeGuards';

@Module({ namespaced: true })
export default class ArticleModule extends VuexModule {
    Articles: Article[] = [];
    error: string | null = null;
    Article: Article | null = null;

    @Mutation
    setArticles(Articles: Article[]) {
        this.Articles = Articles;
    }

    @Mutation
    setArticle(Article: Article) {
        this.Article = Article;
    }

    @Mutation
    setError(error: string | null) {
        this.error = error;
    }

    @Action
    async fetchArticles({ page, pageSize, sortBy, sortOrder, search }: { page: number; pageSize: number; sortBy: string; sortOrder: string; search: string }) {
        try {
            const response = await ArticleService.getAll(page, pageSize, sortBy, sortOrder, search);

            if (isErrorResponse(response)) {
                this.context.commit('setError', response.message || 'An error occurred while fetching articles.');
                return null; // Return null on error
            } else {
                const { data, totalRows } = response;
                this.context.commit('setArticles', data);
                console.log(data);
                
                return { data, totalRows };
            }
        } catch (error: any) {
            this.context.commit('setError', error.message || 'An error occurred while fetching articles.');
            return null; // Return null on error
        }
    }

    @Action
    async fetchArticlesConsomme({ page, pageSize, sortBy, sortOrder, search }: { page: number; pageSize: number; sortBy: string; sortOrder: string; search: string }) {
        try {
            const response = await ArticleService.getAllConsomme(page, pageSize, sortBy, sortOrder, search);

            if (isErrorResponse(response)) {
                this.context.commit('setError', response.message || 'An error occurred while fetching articles.');
                return null; // Return null on error
            } else {
                const { data, totalRows } = response;
                this.context.commit('setArticles', data);
                console.log(data);
                
                return { data, totalRows };
            }
        } catch (error: any) {
            this.context.commit('setError', error.message || 'An error occurred while fetching articles.');
            return null; // Return null on error
        }
    }



    @Action
    async getById(id: number) {
        try {
            const response = await ArticleService.getById(id);

            if (isErrorResponse(response)) {
                console.log(`Article with ID ${id} not found.`);
            } else {
                const Article = response;
                this.context.commit('setArticle', Article);
                return Article; // Correction ici
            }
            this.context.commit('setError', null);
             
        } catch (error: any) {
            this.context.commit('setError', error.message || 'An error occurred');
        }
    }

    @Action
    async supprimerById(id: number) {
        try {
            const response = await ArticleService.supprimer(id);

            if (isErrorResponse(response)) {
                console.log(`Article with ID ${id} not found.`);
            } else {
                const Article = response;
                this.context.commit('setArticle', Article);
                return Article; // Correction ici
            }
            this.context.commit('setError', null);
        } catch (error: any) {
            this.context.commit('setError', error.message || 'An error occurred');
        }
    }

    @Action
    async addArticle(Article: Article) {
        try {
            const response = await ArticleService.create(Article);
            return response;
        } catch (error: any) {
            this.context.commit('setError', error.message || 'An error occurred');
            console.error('Error adding Article:', error);
        }
    }

    @Action
    async addArticlesWithImport(file: File[]) {
        try {
            const response = await ArticleService.createwithImport(file);
            return response;
        } catch (error: any) {
            console.error('Error adding articles with import:', error);
            throw error;
        }
    }

    @Action
    async updateArticle({ id, article } : { id: number; article: Article }) {
        try {
            const response = await ArticleService.update({id, article});
            return response;
        } catch (error: any) {
            this.context.commit('setError', error.message || 'An error occurred');
            console.error('Error updating Article:', error);
        }
    }

    @Action
    async extractArticles({ id, page, pageSize, sortBy, sortOrder, search }: { id: number, page: number; pageSize: number; sortBy: string; sortOrder: string; search: string }) {
        try {
            const response = await ArticleService.extractArticles({ id, page, pageSize, sortBy, sortOrder, search });
            if (isErrorResponse(response)) {
                this.context.commit('setError', response.message || 'An error occurred while extracting articles.');
                return null; // Return null on error
            }
            return response;
        } catch (error: any) {
            return error
        }
    }

    get getAll() {
        return this.Articles;
    }

    get getArticle() {
        return this.Article;
    }
}
