import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import MarqueService from '@/Services/MarqueService';
import { Marque } from '@/models/Marque';
@Module({ namespaced: true })
export default class MarqueModule extends VuexModule {
    Marques: Marque[] = [];
    error: string | null = null;
    @Mutation
    setNatures(Marques: Marque[]) {
        this.Marques = Marques;
    }
    @Mutation
    setError(error: string | null) {
        this.error = error;
    }

    @Action
    async fetchMarques() {
        try {
            const response = await MarqueService.getAllMAERQUES();
            return response
        } catch (error: any) {
            return error;
        }
    }
    get getAll() {
        return this.Marques;
    }

}
