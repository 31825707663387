import axiosInstance from '@/utils/axiosConfig'; // Importez l'instance Axios configurée
import axios from 'axios';

const API_URL = '/zones'; // Chemin de base pour les zones

const ZoneService = {
    async getAllZones() {
        try {
            const response = await axiosInstance.get(`${API_URL}/all_zone/`);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                // Renvoyer un message d'erreur détaillé
                return Promise.reject({
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while fetching zones.'
                });
            }
            // Retourner une erreur générique en cas d'autres types d'erreurs
            return Promise.reject({
                status: 500,
                message: 'An unexpected error occurred.'
            });
        }
    },
    async getZonesByLocation(id:number) {
        try {
            const response = await axiosInstance.get(`${API_URL}/location/${id}`);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                // Renvoyer un message d'erreur détaillé
                return Promise.reject({
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while fetching zones.'
                });
            }
            // Retourner une erreur générique en cas d'autres types d'erreurs
            return Promise.reject({
                status: 500,
                message: 'An unexpected error occurred.'
            });
        }
    },
};

export default ZoneService;
