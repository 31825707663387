import axiosInstance from '@/utils/axiosConfig'; // Importez l'instance Axios configurée
import axios from 'axios';
const API_URL = '/produits';

const ProduitService = {
    async getAllProduits() {
        try {
            const response = await axiosInstance.get(`${API_URL}/all_produit/`);
            return response.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                // Renvoyer un message d'erreur plus détaillé
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while fetching products.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
    
};

export default ProduitService;
