import axiosInstance from '@/utils/axiosConfig';
import axios from 'axios';

const PermissionService = {
    async getPermissionsByUser() {
        try {
            const response = await axiosInstance.get(`user/permissions/`);
            return { status: response.status, permissions: response.data.permissions };
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return {
                    status: error.response?.status ?? 500,
                    message: error.response?.data?.message ?? 'An error occurred while fetching permissions.'
                };
            }
            return {
                status: 500,
                message: 'An unexpected error occurred.'
            };
        }
    },
};

export default PermissionService;
