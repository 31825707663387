import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import DashboardService from '@/Services/DahsboardService';
@Module({ namespaced: true })
export default class DashboardModule extends VuexModule {

    @Action
    async fetchCountItemsOfCategory() {
        try {
            const response = await DashboardService.getCountItemsOfCategory();
            return response
        } catch (error: any) {
            return error;
        }
    }
    @Action
    async fetchCountArticleRecepted() {
        try {
            const response = await DashboardService.getCountArticlereceptionner();
            return response
        } catch (error: any) {
            return error;
        }
    }
    @Action
    async fetchCountItems() {
        try {
            const response = await DashboardService.getCountItems();
            return response
        } catch (error: any) {
            return error;
        }
    }
    @Action
    async fetchCountTags() {
        try {
            const response = await DashboardService.getCountTags();
            return response
        } catch (error: any) {
            return error;
        }
    }
    @Action
    async fetchCountArchiveItem() {
        try {
            const response = await DashboardService.getCountArchiveItem();
            return response
        } catch (error: any) {
            return error;
        }
    }
    @Action
    async fetchCountTagsOfType() {
        try {
            const response = await DashboardService.getCounttagsOfType();
            return response
        } catch (error: any) {
            return error;
        }
    }
    @Action
    async fetchItemsAmortizition(year:number) {
        try {
            const response = await DashboardService.getCountItemsAmortizition(year);
            return response
        } catch (error: any) {
            return error;
        }
    }
    @Action
    async fetchValueAmortizition() {
        try {
            const response = await DashboardService.getValueAmortizition();
            return response
        } catch (error: any) {
            return error;
        }
    }
    @Action
    async fetchValuePerDepartement(year:number) {
        try {
            const response = await DashboardService.getValuePerDepartement(year);
            return response
        } catch (error: any) {
            return error;
        }
    }
    @Action
    async fetchCountEmplacementPerLocation() {
        try {
            const response = await DashboardService.getCountEmplacementPerLocation();
            return response
        } catch (error: any) {
            return error;
        }
    }
    @Action
    async fetchCountItemPerDepartemnt() {
        try {
            const response = await DashboardService.getCountItemPerDepartemnt();
            return response
        } catch (error: any) {
            return error;
        }
    }
    @Action
    async fetchItemData() {
        try {
            const response = await DashboardService.getCountItemwithValuePerPersonne();
            return response
        } catch (error: any) {
            return error;
        }
    }

}
